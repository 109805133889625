.Navbar {
    background-color: #000000;
    color: #ffffff;
    padding: 0.5rem 5vw;
}

.Navbar ul {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
}


.Navbar li:not(:first-of-type) {
    text-align: center;
}


@media screen and (max-width: 1200px) {
    .Navbar ul {
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 950px) {
    .Navbar {
        display: none;
    }
}
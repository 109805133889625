.Contact form label, input, textarea {
    display: block;
    width: 100%;
}

.Contact form input, textarea {
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    border: 1px #0000003d solid;
}

.Contact p {
    text-align: center;
    margin-bottom: 2rem;
}

.Contact .ContactInfo {
    margin-top: 2rem;
}

.Contact .ContactInfo div {
    display: flex;
    align-items: center;
}

.Contact .ContactInfo div img {
    margin-right: 1rem;
    width: 24px;
}

.Contact form .Error {
    text-align: left;
    color: #9c1616;
}

@media screen and (max-width: 500px) {

    .Contact .ContactInfo div p {
        font-size: 3vw;
    }

}
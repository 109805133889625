.About section:first-of-type {
    margin-bottom: 2rem;
}

.About section h1, img {
    margin-bottom: 2rem;

}

.About section h1 {
    text-align: center;
}

.About section:first-of-type {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.About div.TwoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.About div.TwoCol .ColOne {
    padding-right: 2rem;
}

.About section:nth-of-type(2) div.TwoCol .ColOne h3 {
    margin-bottom: 0;
}

.About section img {
    width: 100%;
}

.About .Cta {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.About .Synopsis {
    font-style: italic;
}

@media screen and (max-width: 1070px) {
    .About div.TwoCol {
        grid-template-columns: 1fr;
    }
    .About div.TwoCol .ColTwo {
        order: -1;
    }
    .About section:nth-of-type(2) div.TwoCol .ColOne {
        padding: 0;
        text-align: center;
        margin-bottom: 2rem;
    }
    .About section:nth-of-type(3) div.TwoCol {
        text-align: center;
        margin-bottom: 2rem;
    }
    .About section:nth-of-type(3) div.TwoCol img{

        margin-top: 2rem;
    }
    .About section:nth-of-type(3) div.TwoCol .ColOne{
        padding: 0;
    }
    .About section:nth-of-type(2) img {
        height: 60vh;
        object-fit: cover;
        object-position: top;
    }
}
.FloorPlan .Images{
    display: grid;
    text-align: center;
}

.FloorPlan img {
    width: 100%;
}

.FloorPlan .MapView {
    margin: 2rem 0;
}
.MobileNavbar {
    background-color: #000000;
    color: #ffffff;
    padding: 1rem 5vw;
    position: sticky;
    top: 0;
    z-index: 100;
}

.MobileNavbar ul:not(.Menu ul) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MobileNavbar .Menu {
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: #000000;
}

.MobileNavbar  .Menu ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.MobileNavbar  .Menu ul li {
    line-height: 10vh;
    font-size: 2rem;
}

@media screen and (min-width: 950px) {
    .MobileNavbar {
        display: none;
    }
}
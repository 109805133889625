* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  font-size: 16px !important;
}

p {
  line-height: 1.5rem;
}

button {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  border: none;
}

button.Purple {
  background-color: #150E1F;
  color: #ffffff;
}

button.WhiteBorder {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-weight: 800;
}

nav li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, button:hover {
  cursor: pointer;
}

a.InlineLink {
  text-decoration: underline;
  font-weight: bold;
}

.Logo {
  width: 5vw;
  margin: 0;
}

.Page {
  width: 900px;
  padding: 5vw 0;
  margin: auto;
}

.Title {
  font-size: 3vw;
  margin-bottom: 5%;
  text-align: center;
}

.Title hr {
  border: 2px solid #000000;
  position: absolute;
}

@media screen and (max-width: 1200px) {
  .Logo {
    width: 7vw;
  }

  .Title {
    font-size: 5vw;
  }
}


@media screen and (max-width: 1070px) {
  .Page {
    width: 100%;
    padding: 5vw;
  }
}

@media screen and (max-width: 800px) {
  .Logo {
    width: 9vw;
  }

  .Title {
    font-size: 7vw;
  }
}

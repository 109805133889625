.Home .Hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url("https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/291469629_1459334144502956_144440046225446976_n.jpg?alt=media&token=06e0e40c-1f67-45b9-948f-176f8e50c09b");
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Home .Hero .Text {
    padding: 2rem;
    color: #ffffff;
    background-color: #00000095;
    text-align: center;
}

.Home .Hero .Text h1 {
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0.2rem;
}

.Home .Hero .Text button {
    margin-top: 1rem;
}

.Home .About, .Home .Contact {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw 25vw;
}

.Home .About h1, h3 {
    margin-bottom: 1rem;
}

.Home .About p {
    margin-bottom: 1rem;
}

.Home .Gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Home .Gallery img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin: 0;
}

@media screen and (max-width: 1150px) {
    .Home .Gallery {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    .Home .Gallery {
        grid-template-columns: 1fr;
    }
    .Home .About, .Home .Contact {
        padding: 5vw;
    }
    .Home .Hero {
        background-position: right;
    }
}

@media screen and (max-width: 650px) {
    .Home .Gallery {
        grid-template-columns: 1fr;
    }
}
.Gallery {
    padding: 5vw 0;
}

.Gallery .Images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Gallery .Images img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin: 0;
}

@media screen and (max-width: 1150px) {
    .Gallery .Images {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    .Gallery .Images {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 650px) {
    .Gallery .Images {
        grid-template-columns: 1fr;
    }
}
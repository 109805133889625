.FloorPlans .Plans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}


.FloorPlans .Plans .Plan {
    margin-bottom: 2rem;
}


.FloorPlans .Plans .Plan .PlanImages img {
    width: 100%;
}
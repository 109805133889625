.MessageSent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.2s;
    z-index: 200;
}

.Success {
    background-color: #5F8D4E;
}

.Failed {
    background-color: #F24C3D;
}

.MessageSent p {
    color: #ffffff;
    font-weight: bold;
    margin: 0 !important;
}